/**
 *  Breakpoints
 * - xs, extra-small: 0px
 * - sm, small: 600px
 * - md, medium: 960px
 * - lg, large: 1280px
 * - xl, extra-large: 1920px
 * @look_at https://material-ui.com/customization/breakpoints/
 **/
import React from "react"
// matrial-ui methods
import { makeStyles } from "@material-ui/core/styles"
// matrial-ui components
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
/* */
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

// loacl components
import Logo from "@atoms/Logo"
import Link from "@atoms/Link"
import CopyRight from "@molecules/CopyRight"

// Icons
import FacebookIcon from "@icons/FacebookIcon"
import TwitterIcon from "@icons/TwitterIcon"
import InstagramIcon from "@icons/InstagramIcon"

/**
 * @custom_style
 **/
const useStyles = makeStyles(theme => ({
  warrp: {
    display: "flex",
    padding: "25px 0",
    // css here
    // my custom style
    [theme.breakpoints.up("md")]: {
      // responsive here
    },
  },
  list: {
    paddingLeft: 25,
  },
  listHead: {
    fontWeight: "bold",
    fontSize: 15,
    color: "#F55739",
  },
  icon: {
    margin: 8,
    [theme.breakpoints.up("md")]: {
      // responsive here
      //width: `calc(100%)`,
      //background: theme.palette.background.paper,
    },
  },
}))

/**
 *
 * @param {*} props
 * @returns
 */
export default function Footer(props) {
  // use our custom style
  const classes = useStyles()

  // width come from matrial-ui props for media query
  //const { width } = props;

  return (
    <Box>
      {/* LOGO*/}
      <Box>
        <Logo />
      </Box>

      {/* ICONS*/}
      <Box className={classes.warrp}>
        <Box className={classes.icon}>
          <TwitterIcon />
        </Box>
        <Box className={classes.icon}>
          <InstagramIcon />
        </Box>
        <Box className={classes.icon}>
          <FacebookIcon />
        </Box>
      </Box>

      {/*  */}
      <CopyRight />
    </Box>
  )
}
