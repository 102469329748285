import React from "react"
import FolderIcon from "@icons/FolderIcon"
import LikeIcon from "@icons/LikeIcon"
import DollerIcon from "@icons/DollerIcon"
import LogoutIcon from "@icons/LogoutIcon"
//
export const navs = [
  {
    title: "طريقة عملنا",
    to: "/#workway",
    icon: <FolderIcon />,
  },
  {
    title: "دوّر لي سيارة",
    to: "/car-search",
    icon: <LikeIcon />,
  },
  {
    title: "بيع سياراتك ",
    to: "/car-sell",
    icon: <DollerIcon />,
  },
]
