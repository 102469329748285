import React from "react"
import { makeStyles } from "@material-ui/core/styles"
// for reponsive
import Hidden from "@material-ui/core/Hidden"
import withWidth from "@material-ui/core/withWidth"
import PropTypes from "prop-types"
//list components
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Typography from "@material-ui/core/Typography"
// button component
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@icons/MenuIcon"
import Avatar from "@material-ui/core/Avatar"
import Link from "@atoms/Link"
// nav data
import { navs } from "./navs.js"

/**
 * @custom_style
 */
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
})

/**
 *
 **/
function MobileNav() {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  return (
    <Hidden mdUp>
      <IconButton
        onClick={toggleDrawer("left", true)}
        edge="start"
        aria-label="open drawer"
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <List component="nav" aria-labelledby="nested-list-subheader">
          <Divider />
        </List>

        {/** nav list */}

        <List>
          {navs.map((nav, index) => (
            <Link to={nav.to} key={index}>
              <ListItem style={{ textAlign: "right" }}>
                <ListItemIcon>{nav.icon}</ListItemIcon>
                <ListItemText primary={nav.title} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </Hidden>
  )
}

MobileNav.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
}

export default withWidth()(MobileNav)
