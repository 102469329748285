/**
 *  Breakpoints
 * - xs, extra-small: 0px
 * - sm, small: 600px
 * - md, medium: 960px
 * - lg, large: 1280px
 * - xl, extra-large: 1920px
 * @look_at https://material-ui.com/customization/breakpoints/
 **/
import React from "react"
import Link from "@atoms/Link"
// gatsby
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
// matrial-ui methods
import { makeStyles } from "@material-ui/core/styles"
// matrial-ui components
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

// loacl components

/**
 * @custom_style
 **/
const useStyles = makeStyles(theme => ({
  payment: {
    width: 32,
    margin: 8,
  },
  link: {
    padding: "3px 15px",
  },
}))

/**
 *
 * @param {*} props
 * @returns
 */
export default function CopyRight(props) {
  // use our custom style
  const classes = useStyles()

  // width come from matrial-ui props for media query
  //const { width } = props;

  const data = useStaticQuery(graphql`
    query IndexPaymentsImagesQuery {
      mastercard: file(relativePath: { eq: "payments/mastercard.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      visa: file(relativePath: { eq: "payments/visa.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      paypal: file(relativePath: { eq: "payments/paypal.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Box style={{ padding: 10 }}>
      {/* LOGO*/}
      <Grid container spacing={3}>
        {/* copyright and payment container*/}
        <Grid item container xs={6} sm={6} md={6} lg={6} xl={6}>
          {/* COPYRIGHT grid*/}
          <Grid item sm={12} md={6} lg={6} xl={6}>
            <Typography
              variant="h6"
              style={{ fontSize: 15, fontWeight: "bold" }}
              gutterBottom
            >
              كل الحقوق المحفوظة دوّر لي ٢٠٢١
            </Typography>
          </Grid>

          {/* payments methods grid*/}
          <Grid item sm={12} md={6} lg={6} xl={6} style={{ display: "flex" }}>
            <Box className={classes.payment} style={{ width: 17 }}>
              <Img fluid={data.mastercard.childImageSharp.fluid} />
            </Box>

            <Box className={classes.payment}>
              <Img fluid={data.visa.childImageSharp.fluid} />
            </Box>

            <Box className={classes.payment}>
              <Img fluid={data.paypal.childImageSharp.fluid} />
            </Box>
          </Grid>
        </Grid>

        {/*
         {/* important links }
          <Grid item  sm={12}  md={6} lg={6} xl={6}>

             <Grid container>
              {/* lins container }
               <Grid item className={classes.link}>
                  <Link to="#">
                     سياسية ملفات الارتباط 
                  </Link>
               </Grid>

               <Grid item className={classes.link}>
                 <Link  to="#">
                   تعليمات الاستخدام 
                 </Link>
                </Grid>

               <Grid item className={classes.link}>
                 <Link  to="#">
                   سياسية الخصوصية
                 </Link>
               </Grid>

              </Grid>

            </Grid>
*/}
      </Grid>
    </Box>
  )
}
